// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demos-luvcoffee-2020-mdx": () => import("./../../../src/pages/demos/luvcoffee2020.mdx" /* webpackChunkName: "component---src-pages-demos-luvcoffee-2020-mdx" */),
  "component---src-pages-demos-noma-2020-mdx": () => import("./../../../src/pages/demos/noma2020.mdx" /* webpackChunkName: "component---src-pages-demos-noma-2020-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-brand-ipsum-mdx": () => import("./../../../src/pages/projects/brand-ipsum.mdx" /* webpackChunkName: "component---src-pages-projects-brand-ipsum-mdx" */),
  "component---src-pages-projects-pretty-password-mdx": () => import("./../../../src/pages/projects/pretty-password.mdx" /* webpackChunkName: "component---src-pages-projects-pretty-password-mdx" */),
  "component---src-pages-services-branding-js": () => import("./../../../src/pages/services/branding.js" /* webpackChunkName: "component---src-pages-services-branding-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-case-template-js": () => import("./../../../src/templates/caseTemplate.js" /* webpackChunkName: "component---src-templates-case-template-js" */)
}

