export const Buttons = {
  baseStyle: {
    _focus: { boxShadow: 'none' },
    _hover: {
      textDecoration: 'none',
    },
    borderRadius: '0px',
    // paddingLeft: '2rem',
    py: '1.5rem',
    px: '2.5rem',
  },
  variants: {
    btnCTA: {
      backgroundColor: 'brand.five',
    },
    btnOne: {
      color: 'brand.four',
      borderColor: 'brand.six',
      border: '2px',
      _active: {
        backgroundColor: 'brand.four',
        borderColor: 'brand.six',
        border: '2px',
        color: 'brand.one',
      },
      _hover: {
        backgroundColor: 'brand.four',
        borderColor: 'brand.six',
        border: '2px',
        color: 'brand.one',
      },
    },
    btnTwo: {
      // backgroundColor: 'transparent',
      color: 'brand.four',
      border: '2px',
      borderColor: 'brand.three',
      _active: {
        backgroundColor: 'brand.four',
      },
      _hover: {
        backgroundColor: 'brand.three',
        color: 'brand.one',
      },
    },
  },
}

export const Links = {
  baseStyle: {
    color: 'brand.five',
    textDecoration: 'underline',
  },
  variants: {
    light: {
      color: 'brand.one'
    },
    linkOne: {
      color: 'brand.five',
      paddingRight: '.5rem',
      paddingLeft: '.5rem',
      position: 'relative',
      fontWeight: 900,
      textAlign: 'center',
      textDecoration: 'none',
      letterSpacing: '.1rem',
      zIndex: 0,
      _after: {
        textDecoration: 'none',
        margin: 'auto',
        transition: 'all 0.15s ease-out',
        zIndex: '-1',
        display: 'block',
        background: 'brand.five',
        position: 'absolute',
        content: '""',
        height: '1px',
        width: '100%',
        paddingRight: '0rem',
        paddingLeft: '0rem',
        bottom: '-5px',
        right: '0%',
      },
      _active: {
        textDecoration: 'none',
        _after: {
          margin: 'auto',
          display: 'block',
          zIndex: '-1',
          background: 'brand.five',
          position: 'absolute',
          content: '""',
          width: '70%',
          height: '1px',
        },
      },
      _hover: {
        textDecoration: 'none',
        _after: {
          margin: 'auto',
          display: 'block',
          zIndex: '-1',
          background: 'brand.five',
          position: 'absolute',
          content: '""',
          width: '80%',
          height: '1px',
        },
      },
    },
    linkOneLight: {
      color: 'brand.zero',
      paddingRight: '.5rem',
      paddingLeft: '.5rem',
      position: 'relative',
      fontWeight: 900,
      textDecoration: 'none',
      letterSpacing: '.1rem',
      zIndex: 0,
      _after: {
        transition: 'all 0.15s ease-out',
        zIndex: '-1',
        textDecoration: 'none',
        background: 'brand.zero',
        position: 'absolute',
        content: '""',
        height: '1px',
        width: '100%',
        paddingRight: '0rem',
        paddingLeft: '0rem',
        bottom: '-5px',
        right: '0%',
      },
      _active: {
        textDecoration: 'none',
        _after: {
          zIndex: '-1',
          background: 'brand.zero',
          position: 'absolute',
          margin: 'auto',
          display: 'block',
          content: '""',
          width: '70%',
          height: '1px',
        },
      },
      _hover: {
        textDecoration: 'none',
        _after: {
          zIndex: '-1',
          background: 'brand.zero',
          position: 'absolute',
          margin: 'auto',
          display: 'block',
          content: '""',
          width: '80%',
          height: '1px',
        },
      },
    },
    linkInlineLight: {
      color: 'brand.zero',
      paddingRight: '.3rem',
      paddingLeft: '.3rem',
      position: 'relative',
      fontWeight: 900,
      letterSpacing: '.1rem',
      _after: {
        transition: 'all 0.15s ease-out',
        zIndex: '-1',
        // bgGradient: 'linear(to-br, , brand.one 80%,  brand.three )',
        background: 'brand.zero',
        position: 'absolute',
        content: '""',
        height: '5px',
        width: '100%',
        paddingRight: '0rem',
        paddingLeft: '0rem',
        bottom: '-5px',
        right: '0%',
      },
      _active: {
        _after: {
          zIndex: '-1',
          // bgGradient: 'linear(to-br,  brand.three 80%,  brand.one )',
          background: 'brand.zero',
          position: 'absolute',

          content: '""',
          width: '100%',
          height: '40%',
        },
      },
      _hover: {
        _after: {
          zIndex: '-1',
          // bgGradient: 'linear(to-br,  brand.three 80%,  brand.one )',
          background: 'brand.zero',
          position: 'absolute',

          content: '""',
          width: '100%',
          height: '40%',
        },
      },
    },
  },
}
